import Marquee from "@components/Marquee";
import {
	Table,
	TableCell,
	TableData,
	TableHeader,
	TableRow,
} from "@components/core/Table";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import { ItemLoader } from "@components/loaders";
import {
	ArtistNames,
	renderArtistNames,
} from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { getItemPrice } from "@lib/utils";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Release } from "@models/release";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import {
	ArtworkAndControls,
	Exclusive,
	LoaderWrapper,
	ReleaseName,
	TrackNo,
} from "../Tables.shared.style";
import Loader from "./Loader";
import { ReleasesWrapper } from "./ReleasesTable.style";

interface Props {
	isLoading: boolean;
	releases?: Release[];
	cartReleases?: CartItem<Release>[];
	itemCartActions?: (cartItem: CartItem<Release>) => JSX.Element;
	formatSelector?: (cartItem: CartItem<Release>) => JSX.Element;
	audioFormats?: Record<number, Audioformat>;
	showNumbers?: boolean;
	location?: string;
	loadingItems?: number[];
}

const ReleasesTable: React.FC<Props> = ({
	isLoading,
	cartReleases,
	itemCartActions,
	formatSelector,
	audioFormats,
	releases = [],
	showNumbers = true,
	location,
	loadingItems,
}) => {
	const { t } = useTranslation("translation");
	const { currentTrack } = usePlayerState();

	const filteredReleases = cartReleases ?
			cartReleases
				.filter((r) => r.item !== undefined)
				.map((c) => {
					c.item.cart_item_data = c;
					return c.item;
				}) :
		releases;

	const isCartTable = cartReleases !== undefined;

	if (isLoading) {
		return <Loader />;
	}

	return (
		<ReleasesWrapper
			className={cls(
				showNumbers ? "numbers" : undefined,
				isCartTable ? "cart-table" : undefined,
			)}
		>
			<Table>
				<TableHeader className="row">
					<TableCell className="controls">&nbsp;</TableCell>
					<TableCell className="title">
						{`${t("Title")} / ${t(
							"Artists",
						)}`}
					</TableCell>
					<TableCell className="label">{t("Label.Title")}</TableCell>
					{!isCartTable && (
						<TableCell className="bpm">BPM</TableCell>
					)}
					<TableCell className="date">{t("Released")}</TableCell>
					{isCartTable && (
						<>
							<TableCell
								className="format"
							>
								{t("Format")}
							</TableCell>
							<TableCell
								className="price"
							>
								{t("Price")}
							</TableCell>
							<TableCell className={cls("cart-actions")}>
								{t("CartActions")}
							</TableCell>
						</>
					)}
					{!isCartTable && <TableCell className="card">&nbsp;</TableCell>}
				</TableHeader>
				<TableData>
					{filteredReleases.map((release, index) => {
						const itemLoading = release.cart_item_data && loadingItems?.includes(release.cart_item_data?.id);
						return (
							<TableRow
								key={`release-${release.id}`}
								className={cls(
									"row",
									currentTrack &&
									currentTrack.release &&
									currentTrack.release.id === release.id ?
										"current" :
										undefined,
								)}
							>
								<TableCell className="controls">
									<ArtworkAndControls>
										<Link
											href={`/release/${release.slug}/${release.id}`}
											prefetch={false}
											title={release.name}
											className="artwork"
										>
											<Image
												src={dynamicImageUrl({ imageUri: release.image?.uri, size: "md" })}
												alt={release.name}
												width={38}
												height={38}
											/>
											{release.exclusive && (
												<Exclusive>{t("Exclusive")}</Exclusive>
											)}
										</Link>
										{showNumbers && <TrackNo data-testid="track-number">{index + 1}</TrackNo>}
										<span className="fade">
											<Play releaseId={release.id} />
										</span>
										<span className="fade">
											<AddToQueue releaseId={release.id} />
										</span>
										<span className="fade">
											<AddToPlaylist releaseId={release.id} />
										</span>
									</ArtworkAndControls>
								</TableCell>
								<TableCell className="cell title">
									<div className="container">
										<Link
											href={`/release/${release.slug}/${release.id}`}
											prefetch={false}
											title={release.name}
										>
											<Marquee>
												<ReleaseName>{release.name} </ReleaseName>
											</Marquee>
										</Link>
										{release.artists && release.artists.length > 0 && (
											<ArtistNames>
												<Marquee>
													{renderArtistNames(release.artists, { location })}
												</Marquee>
											</ArtistNames>
										)}
									</div>
								</TableCell>
								<TableCell className="cell label">
									<LabelLink label={release.label} location={location} withMarquee />
								</TableCell>
								{!isCartTable && (
									<TableCell className="cell bpm">
										{release.bpm_range.min === release.bpm_range.max ?
											`${release.bpm_range.max} BPM` :
											`${release.bpm_range.min}-${release.bpm_range.max} BPM`}
									</TableCell>
								)}
								<TableCell className="cell date">
									{release.new_release_date ?
										release.new_release_date :
										release.publish_date}
								</TableCell>
								{isCartTable && itemCartActions && release.cart_item_data && (
									<>
										{itemLoading ?
												(
													<LoaderWrapper>
														<ItemLoader />
													</LoaderWrapper>
												) :
												(
													<>
														{formatSelector && (
															<TableCell className="cell format">
																{formatSelector(release.cart_item_data)}
															</TableCell>
														)}
														<TableCell className="cell price">
															{getItemPrice({
																item: release.cart_item_data,
																audioFormatsObject: audioFormats || {},
															})}
														</TableCell>
														{itemCartActions && (
															<TableCell className="cell cart-actions">
																{itemCartActions(release.cart_item_data)}
															</TableCell>
														)}
													</>
												)}
									</>
								)}
								{!isCartTable && (
									<TableCell className="cell card">
										<span className="fade">
											<AddToCart location={location} release={release} />
										</span>
									</TableCell>
								)}
							</TableRow>
						);
					})}
				</TableData>
			</Table>
		</ReleasesWrapper>
	);
};

export default ReleasesTable;
