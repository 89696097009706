import styled from "styled-components";
import { Wrapper } from "../Tables.shared.style";

export const Genres = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReleasesWrapper = styled(Wrapper)`

  .label {
    width: 11rem;
    min-width: 11rem;
  }

  .date {
    width: 10rem;
    min-width: 10rem;
  }

  .bpm {
    width: 8.75rem;
    min-width: 8.75rem;
  }

  .cell {
    &.format {
      padding-left: 0;
    }
  }

  .price {
    width: 5rem;
    min-width: 5rem;
  }
`;
